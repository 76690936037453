.textEditor-editor {
    border-radius: 4px;
    border: solid 1px #819CAD;
    color: #004269;
    background-color: #fff;
    padding: 15px;
    font-size: 14px;
    box-sizing: border-box;
    outline: none;
    -moz-appearance: textfield;
    font-family: Poppins;
    box-sizing: border-box;
}