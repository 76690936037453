@import "@yaireo/tagify/dist/tagify.css";


.tagify {
  margin-bottom: 3em;
  --tag-bg: #b3c6d3;
  --tag-text-color: white;
  --tag-text-color--edit: var(--tag-bg);
  --tag-hover: rgb(162,8,56);
  --tag-remove-btn-color: white;
  min-height: 50px;
  border-radius: 5px;
}

.clearAllBtn{
  float: right;
  margin-bottom: .5em;
  margin-right: 1.5em;

}
.myTagsReadOnly {
  margin-bottom: 0em !important;
  --tag-bg: #b3c6d3;
  --tag-text-color: white;
  --tag-text-color--edit: var(--tag-bg);
  --tag-hover: #b3c6d3;;
  --tag-remove-btn-color: white;
  border: 0px solid black;
}
.myTagsReadOnly :hover {
  background-color: none;
}
.myTagsReadOnly+input, .myTagsReadOnly+textarea  {
 background-color: red;
 position: absolute !important;
 top: 0 !important;
 height: 0px !important;
}

.tagify__tag{
  z-index: 0;
}